<template>
  <div>
    <matter-table
      :tableTh="tableTh"
      :tableData="tableData"
      :height="height"
      ref="table"
      @cell-click="showMatter"
    >
      <el-table-column v-if="from == 'pannel'" :label="'操作'">
        <template slot-scope="scope">
          <div class="active-color" @click="showMatter(scope.row)">
            查看详情
          </div>
        </template>
      </el-table-column>

      <el-table-column v-else :label="'外出/接待'">
        <template slot-scope="scope">
          <div>{{ scope.row.itemType | itemTypeFilter }}</div>
        </template>
      </el-table-column>
    </matter-table>
    <el-row
      v-if="tableData.length > 0"
      type="flex"
      align="middle"
      justify="end"
    >
      <custom-el-pagination
        v-if="total > 10"
        :total="total"
        @changePage="changePage"
        :currentPage="page"
      >
      </custom-el-pagination>
    </el-row>
    <matter ref="matter">
      <div slot="matter">
        <slot name="matter-btns"></slot>
      </div>
    </matter>
  </div>
</template>

<script>
import MatterTable from "@/components/tables";
import Pagination from "@/components/paginations";
import Matter from "@/views/resource_library_module/matter/index";
export default {
  filters: {
    itemTypeFilter(status) {
      switch (status) {
        case 1:
          return "外出";
        case 2:
          return "接待";
      }
    },
  },
  components: {
    MatterTable,
    "custom-el-pagination": Pagination,
    Matter,
  },
  props: {
    tableTh: { type: Array },
    tableData: { type: Array },
    total: { type: Number },
    page: { type: Number },
    height: { type: String },
    from: { type: String },
  },
  methods: {
    showMatter(row) {
      const id = row.id;
      this.$refs.matter.open(id);
    },
    changePage(val) {
      this.$emit("changePage", val);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>